import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { ConfigProvider } from "vant";
import VueSnip from "vue-snip";
import "./assets/tailwind.css";
import "@vant/touch-emulator";
import VCalendar from "v-calendar";
// import axios from "axios";
// axios.defaults.withCredentials = true;

createApp(App)
    .use(store)
    .use(router)
    .use(VueSnip)
    .use(ConfigProvider)
    .use(VCalendar)
    .mount("#app");