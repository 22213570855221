import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    seeMenu: false,
    seeBack: false,
    school: null,
    message: null,
    pageTitle: "泛美舞蹈管理系统",
    classDetails: null,
    messageHistory: null,
    numberNoti: 0,
    lang: "zh",
    lastPage: null,
    timer: null
  },
  mutations: {
    hideBack(state) {
      state.seeBack = false;
    },
    showBack(state) {
      state.seeBack = true;
    },
    hideMenu(state) {
      state.seeMenu = false;
    },
    showMenu(state) {
      state.seeMenu = true;
    },
    setSchool(state, school) {
      state.school = school;
    },
    setUser(state, user) {
      state.user = user;
    },
    setPageTitle(state, title) {
      state.pageTitle = title;
    },
    setClassDetails(state, details) {
      state.classDetails = details;
    },
    setTimer(state, t) {
      if (state.timer) {
        clearInterval(state.timer);
      }
      state.timer = t;
    },
    setMessageHistory(state, history) {
      state.messageHistory = history;
    },
    addNoti(state) {
      state.numberNoti += 1;
    },
    hideNoti(state) {
      state.numberNoti = 0;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setLastPage(state, location) {
      state.lastPage = location;
    }
  },
  actions: {
  },
  modules: {
  }
})