export default {
    student: {
        value: "student",
        name: {
            zh: "学生",
            en: "Student"
        }
    },
    teacher: {
        value: "teacher",
        name: {
            zh: "老师",
            en: "Teacher"
        }
    },
    manager: {
        value: "manager",
        name: {
            zh: "学校管理员",
            en: "Manager"
        }
    }
}