import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Front",
    component: function () {
      return import(/* webpackChunkName: "front" */ "../views/Front.vue");
    }
  },
  {
    path: "/login",
    name: "Login",
    component: function () {
      return import(/* webpackChunkName: "login" */ "../views/PageLogin.vue");
    }
  },
  {
    path: "/reset",
    name: "Reset",
    component: function () {
      return import(/* webpackChunkName: "reset" */ "../views/PageReset.vue");
    }
  },
  {
    path: "/signup/:mode",
    name: "SignUp",
    component: function () {
      return import(/* webpackChunkName: "signup" */ "../views/PageSignup.vue");
    }
  },
  {
    path: "/home",
    name: "Home",
    component: function () {
      return import(/* webpackChunkName: "home" */ "../views/PageHome.vue");
    }
  },
  {
    path: "/class/:school",
    name: "Class",
    component: function () {
      return import(/* webpackChunkName: "class-search" */ "../views/PageClassSearch.vue");
    }
  },
  {
    path: "/createClass/:mode",
    name: "CreateClass",
    component: function () {
      return import(/* webpackChunkName: "create-class" */ "../views/PageCreateClass.vue");
    }
  },
  {
    path: "/viewTeachers",
    name: "ViewTeachers",
    component: function () {
      return import(/* webpackChunkName: "view-teachers" */ "../views/PageTeachers.vue");
    }
  },
  {
    path: "/viewStudents",
    name: "ViewStudents",
    component: function () {
      return import(/* webpackChunkName: "view-students" */ "../views/PageStudents.vue");
    }
  },
  {
    path: "/viewManagers",
    name: "ViewManagers",
    component: function () {
      return import(/* webpackChunkName: "view-managers" */ "../views/PageManagers.vue");
    }
  },
  {
    path: "/viewClassList",
    name: "ViewClassList",
    component: function () {
      return import(/* webpackChunkName: "view-class-list" */ "../views/PageClassList.vue");
    }
  },
  {
    path: "/classDetails/:classId",
    name: "ClassDetails",
    component: function () {
      return import(/* webpackChunkName: "class-details" */ "../views/PageClassDetails.vue");
    }
  },
  {
    path: "/classStudents/:classId",
    name: "ClassStudents",
    component: function () {
      return import(/* webpackChunkName: "class-students" */ "../views/PageClassStudents.vue");
    }
  },
  {
    path: "/classAttendance/:classId/:year/:month/:stars",
    name: "ClassAttendance",
    component: function () {
      return import(/* webpackChunkName: "class-attendance" */ "../views/PageClassAttendance.vue");
    }
  },
  {
    path: "/classSession/:classId/:year/:month",
    name: "ClassSession",
    component: function () {
      return import(/* webpackChunkName: "class-session" */ "../views/PageClassSession.vue");
    }
  },
  {
    path: "/paymentProfile",
    name: "PaymentProfile",
    component: function () {
      return import(/* webpackChunkName: "payment-profile" */ "../views/PagePaymentProfile.vue");
    }
  },
  {
    path: "/classPayments/:classId",
    name: "ClassPayments",
    component: function () {
      return import(/* webpackChunkName: "class-payments" */ "../views/PageClassPayments.vue");
    }
  },
  {
    path: "/studentDetails/:studentId/:mode/:targetId",
    name: "StudentDetails",
    component: function () {
      return import(/* webpackChunkName: "student-details" */ "../views/PageStudentDetails.vue");
    }
  },
  {
    path: "/studentClass/:studentId",
    name: "StudentClass",
    component: function () {
      return import(/* webpackChunkName: "student-class" */ "../views/PageStudentClass.vue");
    }
  },
  {
    path: "/teacherClass/:teacherId",
    name: "TeacherClass",
    component: function () {
      return import(/* webpackChunkName: "teacher-class" */ "../views/PageTeacherClass.vue");
    }
  },
  {
    path: "/message/:peerId",
    name: "Message",
    component: function () {
      return import(/* webpackChunkName: "message" */ "../views/PageMessage.vue");
    }
  },
  {
    path: "/classForm/:classId",
    name: "ClassForm",
    component: function () {
      return import(/* webpackChunkName: "class-form" */ "../views/PageClassForm.vue");
    }
  },
  {
    path: "/classTask/:classId/",
    name: "ClassTask",
    component: function () {
      return import(/* webpackChunkName: "class-task" */ "../views/PageClassTask.vue");
    }
  },
  {
    path: "/transactionRecords/:classId/",
    name: "TransactionRecords",
    component: function () {
      return import(/* webpackChunkName: "transaction-records" */ "../views/PageTransactionRecords.vue");
    }
  },
  {
    path: "/resources/:group/",
    name: "FileRecources",
    component: function () {
      return import(/* webpackChunkName: "file-resources" */ "../views/PageResources.vue");
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

export default router;
