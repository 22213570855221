export default {
    en: {
        back: "Back",
        titleMyPage: "My Schedule",
        titleAllClass: "All Classes",
        titleMyClass: "My Classes",
        titleMyMessage: "Messages",
        titleMyInfo: "Profile",
        titleRecords: "Payments",
        titleLogout: "Sign Out"
    },
    zh: {
        back: "返回",
        titleMyPage: "我的主页",
        titleAllClass: "所有课程",
        titleMyClass: "我的课程",
        titleMyMessage: "我的消息",
        titleMyInfo: "我的信息",
        titleRecords: "支付记录",
        titleLogout: "登出"
    }
}