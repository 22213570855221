export default {
    displayPath: "images/",
    filePath: "https://fanmei-store.s3.us-west-2.amazonaws.com/",
    userPath: "https://fanmei-store.s3.us-west-2.amazonaws.com/users/",
    schoolPath: "https://fanmei-store.s3.us-west-2.amazonaws.com/schools/",
    classPath: "https://fanmei-store.s3.us-west-2.amazonaws.com/classes/",
    apiPath: "/actions/",
    freePath: "/free/"
    // apiPath: "http://127.0.0.1:8081/actions/",
    // freePath: "http://127.0.0.1:8081/free/"
}